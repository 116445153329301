.container {
  border-top: 1px solid #c9c1d4;
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin: 0 auto;
  padding: 1rem 0;
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    .title {
      font-size: 1.1rem;
      font-weight: 600;
      margin-bottom: 1.3rem;
      text-transform: capitalize;
    }
    .menu {
      margin-bottom: 1rem;
      font-size: 0.9rem;
      &:last-child {
        margin-bottom: 0rem;
      }
    }
    .icon {
      font-size: 2rem;
      width: 100%;
      display: flex;
      justify-content: space-evenly;
    }
  }
}

@media screen and (max-width: 1180px) {
  .container {
    justify-content: space-between;
    padding: 1rem 0.5rem;
    .wrapper {
      padding: 0.5rem;
      .title {
        font-size: 0.9rem;
        font-weight: 600;
        margin-bottom: 1.1rem;
        text-transform: capitalize;
      }
      .menu {
        margin-bottom: 0.7rem;
        font-size: 0.75rem;
        &:last-child {
          margin-bottom: 0rem;
        }
      }
      .icon {
        font-size: 1.8rem;
        width: 100%;
        display: flex;
        justify-content: space-evenly;
      }
    }
  }
}
