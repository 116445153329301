.wrapper {
  display: flex;
  width: 20%;
  align-items: center;
  justify-content: space-evenly;
  .quantity {
    height: 2.5rem;
    background: rgba(199, 191, 211, 0.5);
    width: 50%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 1.2rem;
    border-radius: 10px;
    span {
      height: 100%;
      display: flex;
      align-items: center;
      &:first-child,
      &:last-child {
        cursor: pointer;
        font-size: 0.8rem;
      }
    }
  }
}

@media screen and (max-width: 1180px) {
  .wrapper {
    width: 32%;
    .quantity {
      height: 2rem;
      width: 70%;
      font-size: 1rem;
      span {
        &:first-child,
        &:last-child {
          cursor: pointer;
          font-size: 0.6rem;
        }
      }
    }
  }
}
