.wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal {
  background: white;
  border-radius: 0.5rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 40rem;
  width: 30rem;
  z-index: 1001;
  animation: moveInModal 0.5s ease-out;
}
.squareModal {
  width: 35rem;
  height: 35rem;
}

@keyframes moveInModal {
  0% {
    opacity: 0;
    top: 0%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  100% {
    opacity: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
