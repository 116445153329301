.formError {
  margin: -2.5rem auto -2rem;
  color: #bf2600;
  border: 1px solid #ffa9a9;
  height: 2.5rem;
  font-size: 1rem;
  width: 90%;
  background: #fff5f5;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  .icon {
    cursor: pointer;
  }
}
