.container {
  width: 100%;
  margin: 4rem 0;
  .story {
    width: 55%;
    margin: 2rem auto;
    border: 2px solid #c9c1d4;
    border-radius: 20px;
    padding: 2rem;
    p {
      margin: 2rem auto;
      text-align: center;
      font-size: 1.1rem;
      line-height: 1.5;
      color: #444343;
      &:last-child {
        font-size: 1.4rem;
      }
    }
  }
}

@media screen and (max-width: 1180px) {
  .container {
    margin: 2rem 0;
    .story {
      width: 90%;
      margin: 2rem auto;
      border: 1px solid #c9c1d4;
      padding: 1rem 1.5rem;
      p {
        font-size: 1rem;
        &:last-child {
          font-size: 1.2rem;
        }
      }
    }
  }
}
