.wrapper {
  width: 45%;
  a {
    display: flex;
    align-items: center;
    .image {
      height: 10rem;
      margin-right: 3rem;
      img {
        height: 100%;
      }
    }
    .name {
      font-size: 1rem;
      p {
        text-transform: capitalize;
      }
    }
  }
}

@media screen and (max-width: 1180px) {
  .wrapper {
    width: 25%;
    a {
      flex-direction: column;
      .image {
        height: 6rem;
        margin-right: 0;
      }
      .name {
        font-size: 0.9rem;
        margin: 0.5rem 0 0;
        text-align: center;
        p {
          line-height: 1.2rem;
        }
      }
    }
  }
}
