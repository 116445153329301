.btn {
  border: 1px solid #c9c1d4;
  color: #000;
  width: 15rem;
  height: 3rem;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.4s ease-in-out;
  margin: 0.5rem 0 0.5rem;
  cursor: pointer;
  outline: none;
  font-size: 1.1rem;
  text-transform: capitalize;
  &:hover {
    // background: rgba(201, 193, 212, 0.589);
    background: rgb(0, 0, 0);
    color: #fff;
  }
}

@media screen and (max-width: 600px) {
  .btn {
    width: 12rem;
  }
}
