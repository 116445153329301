.container {
  width: 85%;
  margin: 3rem auto;
  .body {
    margin: 5rem 2rem;
    .wrapper {
      min-height: 25rem;
      border-top: 1px solid lightgray;
      border-bottom: 1px solid lightgray;
      .empty {
        margin: 10rem auto;
        text-align: center;
        font-size: 1.5rem;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .container {
    width: 90%;
    margin: 2rem auto 1rem;
    .body {
      margin: 2rem 0 0;
      .wrapper {
        min-height: 22rem;
        border-top: 1px solid lightgray;
        border-bottom: 1px solid lightgray;
        .empty {
          margin: 10rem auto;
          text-align: center;
          font-size: 1rem;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    width: 95%;
    margin: 2rem auto 1rem;
    .body {
      margin: 2rem 0 0;
      .wrapper {
        min-height: 22rem;
        border-top: 1px solid lightgray;
        border-bottom: 1px solid lightgray;
        .empty {
          margin: 10rem auto;
          text-align: center;
          font-size: 1rem;
        }
      }
    }
  }
}
