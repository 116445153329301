.container {
  width: 90%;
  margin: 3rem auto;
  // box-shadow: 0 2px 3px #ccc;
  // border: 1px solid #eee;
  border: 2px solid #c9c1d4;
  border-radius: 20px;

  padding: 2rem 0 1rem;
}

// @media screen and (max-width: 1180px) {
//   .container {
//     width: 45%;
//   }
// }

// @media screen and (max-width: 800px) {
//   .container {
//     width: 65%;
//   }
// }

// @media screen and (max-width: 600px) {
//   .container {
//     width: 75%;
//   }
// }

// @media screen and (max-width: 480px) {
//   .container {
//     width: 90%;
//   }
// }
