.wrapper {
  padding: 0.75rem 0;
  display: flex;
  align-items: center;
  font-weight: 600;
  text-transform: uppercase;
}

@media screen and (max-width: 650px) {
  .wrapper {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 480px) {
  .wrapper {
    font-size: 0.8rem;
  }
}
