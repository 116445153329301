.container {
  width: 70%;
  margin: 4rem auto 6rem;
  h1,
  h2 {
    margin: 2rem;
    text-align: center;
  }
}

@media screen and (max-width: 1200px) {
  .container {
    width: 90%;
  }
}

@media screen and (max-width: 500px) {
  .container {
    width: 98%;
  }
}
