.wrapper {
  width: 100%;
  margin: 2rem 0;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 1180px) {
  .wrapper {
    margin: 1rem 0;
  }
}
