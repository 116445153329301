.container {
  height: 3rem;
  width: 100vw;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  a {
    height: 100%;
  }
  .btn {
    height: 100%;
    width: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #c9c1d4;
    transition: all 0.5s ease;
    font-size: 1.2rem;
    font-weight: 700;
    color: black;
    font-family: 'white-angelica';

    &:hover {
      color: #c9c1d4;
    }
  }
  .active {
    .btn {
      color: #c9c1d4;
    }
  }
}

@media screen and (max-width: 1180px) {
  .container {
    display: none;
  }
}
