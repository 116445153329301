.container {
  margin: 3rem 0 5rem;
  h2 {
    margin-bottom: 3rem;
    text-decoration: underline;
    color: rgb(22, 22, 109);
  }
  .questions {
    margin-bottom: 2rem;
    .question {
      font-size: 1.1rem;
      line-height: 1.4rem;
      font-weight: 600;
      display: flex;
      margin-bottom: 0.5rem;
      .title {
        margin-right: 1rem;
      }
    }
    .answer {
      line-height: 1.4rem;
      display: flex;
      .title {
        margin-right: 1rem;
        font-size: 1.1rem;
        font-weight: 600;
      }
    }
  }
}
