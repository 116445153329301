.container {
  width: 70%;
  margin: 4rem auto;
  main {
    width: 90%;
    margin: 2rem auto;
    border: 2px solid #c9c1d4;
    border-radius: 20px;
    padding: 2rem;
  }
}

@media screen and (max-width: 1200px) {
  .container {
    width: 85%;
  }
}

@media screen and (max-width: 800px) {
  .container {
    width: 95%;
  }
}

@media screen and (max-width: 480px) {
  .container {
    width: 100%;
    main {
      width: 95%;
    }
  }
}

@media screen and (max-width: 400px) {
  .container {
    width: 100%;
    main {
      width: 95%;
      padding: 2rem 1rem;
    }
  }
}
