.title {
  text-align: center;
  margin-bottom: 2rem;
  font-family: "white-angelica";
  font-size: 1.6rem;
  font-weight: 600;
  text-transform: capitalize;
}

@media screen and (max-width: 1180px) {
  .title {
    text-align: center;
    margin-bottom: 1rem;
    font-size: 1.3rem;
  }
}
