.container {
  width: 100vw;
  height: 10rem;
  display: flex;
  background: white;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 100;
  margin-bottom: 2rem;
  transition: img 0.5s ease-in;
  .menu {
    display: none;
  }
  .socialIcons {
    height: 3rem;
    margin: 0.5rem;
    display: flex;
    justify-content: space-evenly;
    margin-left: 5px;
    align-items: center;
    padding: 1rem;
    width: 10rem;
    .sIcon {
      font-size: 2rem;
    }
  }
  .logo {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 10rem;
    display: flex;
    justify-content: center;
  }
  .icons {
    display: flex;
    margin: 0.5rem;
    width: 30%;
    justify-content: space-evenly;
    padding: 0.5rem;
    height: 3rem;
    .icon,
    .cartIcon {
      text-transform: capitalize;
      font-size: 1.5rem;
      display: flex;
      .cartCount {
        margin-left: 0.5rem;
        height: 1.2rem;
        width: 1.2rem;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgb(180, 30, 4);
        font-size: 0.7rem;
        font-weight: 700;
        color: #fff;
      }
      a {
        display: flex;
      }
      p {
        margin-left: 0.5rem;
        font-size: 0.9rem;
      }
    }
  }
}

.textLogo {
  @extend .container;
  height: 5rem;
  box-shadow: 0 0 5px #bbb;
  margin-bottom: 3rem;
}

@media screen and (max-width: 1180px) {
  .container {
    .menu {
      display: inline-block;
      height: 3rem;
      margin: 1rem;
    }
    .socialIcons {
      display: none;
    }
    .icons {
      width: 4rem;
      height: 3rem;
      .icon {
        display: none;
      }
      .cartIcon {
        .cartCount {
          font-size: 0.8rem;
        }
      }
    }
  }
}
