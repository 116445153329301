.container {
  width: 80vw;
  margin: 5rem auto;
  position: relative;
  .carousel {
    width: 100%;
    .wrapper {
      width: 100%;
      border: 1px solid #ccc;
      .thumbnail {
        width: 100%;
        height: 100%;
      }
      .thumbnailMobile {
        display: none;
      }
    }
  }
  .highlights {
    position: absolute;
    width: 40%;
    top: 50%;
    right: 2%;
    transform: translateY(-50%);
    text-transform: uppercase;
    .text {
      font-size: 6rem;
      text-align: center;
      margin: 1rem 0 3rem;
    }
    .btn {
      background: #f3dfe1;
      height: 5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2.3rem;
    }
  }
}

@media screen and (max-width: 1180px) {
  .container {
    margin: 3rem auto;
    .highlights {
      .text {
        font-size: 3rem;
      }
      .btn {
        height: 3rem;
        font-size: 1.5rem;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .container {
    .highlights {
      .text {
        font-size: 2.5rem;
      }
      .btn {
        height: 3rem;
        font-size: 1.2rem;
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .container {
    width: 90vw;
    margin: 2rem auto 4rem;
    position: relative;
    .carousel {
      height: 100%;
      .wrapper {
        width: 100%;
        height: 100%;
        border: 1px solid #ccc;
        .thumbnail {
          display: none;
        }
        .thumbnailMobile {
          width: 100%;
          display: block;
        }
      }
    }
    .highlights {
      position: absolute;
      width: 100%;
      top: 80%;
      text-transform: uppercase;
      .text {
        display: none;
      }
      .btn {
        width: 70%;
        height: 3.5rem;
        font-size: 1.2rem;
        margin: 0 auto;
      }
    }
  }
}
