.container {
  height: 100vh;
  width: 100vw;
  background: rgb(199, 191, 211);
  z-index: 101;
  position: fixed;
  transition: transform 0.7s ease-out;
  top: 0;
  left: 0;
  animation: moveIn 0.7s;
  .header {
    height: 5rem;
    padding: 0.75rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 5px 6px -6px #777;
    .logo {
      height: 70%;
      img {
        height: 100%;
      }
    }
    .icon {
      font-size: 1.5rem;
    }
  }
  .body {
    margin: 3rem auto;
    width: 90%;
    height: 50%;
  }
  .flower {
    height: 5%;
    width: 90%;
    margin: 0 auto;
    img {
      height: 100%;
      display: flex;
      margin: 0 auto;
    }
  }
  .footer {
    width: 100%;
    margin-top: 2rem;
    padding: 0 2rem;
    position: absolute;
    height: 7%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 2rem;
    // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
}

::-webkit-scrollbar {
  display: none;
}

@keyframes moveIn {
  from {
    transform: translatex(-100%);
  }
  to {
    transform: translatex(0);
  }
}

// @keyframes moveOut {
//   from {
//     transform: translatex(0);
//   }
//   to {
//     transform: translatex(-100%);
//   }
// }
