.wrapper {
  display: flex;
  margin: 0.5rem 0;
  width: 15rem;
  justify-content: space-around;
  .title {
    font-weight: 400;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0;
  }
  .price {
    justify-content: center;
    display: flex;
    align-items: center;
  }
}
