.container {
  width: 90%;
  margin: 5rem auto;

  .wrapper {
    margin-top: 1rem;
    border: 1px solid lightgrey;
    width: 100%;
    .desktopOnly {
      width: 100%;
    }
    .mobileOnly {
      display: none;
    }
  }
}

@media screen and (max-width: 650px) {
  .container {
    width: 90%;
    margin: 3rem auto;
    .wrapper {
      margin-top: 2rem;
      border: 1px solid lightgrey;
      width: 100%;
      height: 9rem;
      .desktopOnly {
        display: none;
      }
      .mobileOnly {
        display: block;
        width: 100%;
        height: 8.7rem;
      }
    }
  }
}
