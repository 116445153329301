.wrapper {
  text-transform: uppercase;
  margin: 0 auto;
  font-weight: 200;
  font-size: 1.1rem;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
