.wrapper {
  height: 2.2rem;
  width: 2.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000;
  border-radius: 5px;
}

@media screen and (max-width: 480px) {
  .wrapper {
    height: 2rem;
    width: 2rem;
  }
}
