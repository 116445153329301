.wrapper {
  width: 70%;
  margin: 5rem auto;
  .product {
    display: flex;
    margin: 1rem;
    .thumbnail {
      width: 20rem;
      img {
        width: 100%;
        border: 1px solid lightgray;
      }
    }
    .description {
      width: 70%;
      margin-left: 2rem;
      padding: 1rem;
      .price {
        margin: 1rem 0;
        font-size: 1.2rem;
        font-weight: 800;
      }
      p {
        line-height: 1.5;
        font-size: 0.9rem;
        margin: 1rem 0;
      }
      h2 {
        margin-bottom: 1rem;
      }
      .btnContainer {
        display: flex;
        .btn {
          height: 2.5rem;
          border: 1px solid #000;
          width: 10rem;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 1rem 2rem 0 0;
          transition: all 0.3s ease;
          text-transform: uppercase;
          font-size: 0.9rem;
          cursor: pointer;
          &:hover {
            background: #000;
            color: #fff;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1180px) {
  .wrapper {
    width: 80%;
    .product {
      .thumbnail {
        width: 15rem;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .wrapper {
    width: 85%;
  }
}

@media screen and (max-width: 800px) {
  .wrapper {
    width: 90%;
    .product {
      .description {
        margin-left: 1rem;
        .btnContainer {
          display: flex;
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .wrapper {
    width: 95%;
    margin-bottom: 2rem;
    .product {
      display: flex;
      flex-direction: column;
      align-items: center;
      .description {
        width: 95%;
        margin: 2rem auto 0.5rem;
        padding: 0;
        .btnContainer {
          display: flex;
          width: 100%;
          margin: 0.5rem 0;
          .btn {
            width: 12rem;
            margin: 1rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 380px) {
  .wrapper {
    width: 95%;
    .product {
      .description {
        .btnContainer {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          margin: 0.5rem 0;
          .btn {
            width: 15rem;
            margin: 1rem 1rem 0 0;
          }
        }
      }
    }
  }
}
