.wrapper {
  width: 90%;
  margin: 2rem auto;
  textarea {
    height: 10rem;
    border-radius: 5px;
    width: 100%;
    font-size: 1rem;
    padding: 1rem;
    outline: none;
    border: 1px solid #094892;
    color: #094892;
    transition: all 0.3s ease;
    &:focus {
      border: 1px solid #1b998b;
      font-size: 1.2rem;
      &::placeholder {
        opacity: 0;
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .wrapper {
    width: 90%;
    textarea {
      width: 100%;
    }
  }
}
