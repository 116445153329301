.container {
  margin-top: 3rem;
  .header {
    display: flex;
    width: 90%;
    margin: 3rem auto;
    height: 3rem;
    margin-bottom: 3rem;
    .menu {
      width: 20%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.7rem;
    }
  }
  .body {
    border: 1px solid #fff;
  }
  .btn {
    height: 10%;
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;
  }
}
