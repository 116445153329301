.container {
  margin: 1rem auto;
  width: 50%;
  form {
    width: 90%;
    margin: 4rem auto 1rem;
    .error {
      margin: -1.5rem auto 0;
      width: 90%;
      color: red;
      font-size: 0.8rem;
    }
    .btn {
      margin: 3rem 0rem 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      .signup {
        margin-top: 2rem;
        a {
          color: rgb(23, 23, 133);
        }
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .container {
    width: 70%;
    form {
      width: 90%;
    }
  }
}

@media screen and (max-width: 800px) {
  .container {
    width: 80%;
    form {
      width: 90%;
    }
  }
}

@media screen and (max-width: 600px) {
  .container {
    width: 90%;
    form {
      width: 90%;
      .error {
        width: 85%;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    width: 95%;
    form {
      width: 95%;
      .btn {
        .signup {
          font-size: 0.8rem;
        }
      }
    }
  }
}
