.container {
  width: 70%;
  margin: 4rem auto 6rem;
  h1, h2 {
    margin: 2rem;
    text-align: center;
  }
  .message {
    font-size: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      margin-right: 2rem;
    }
  }
}

@media screen and (max-width: 1200px) {
  .container {
    width: 90%;
  }
}

@media screen and (max-width: 500px) {
  .container {
    width: 98%;
    .message {
      margin-top: 3rem;
      font-size: 1rem;
      display: flex;
      flex-direction: column;
    }
  }
}
