.container {
  width: 70%;
  margin: 4rem auto;
  .personalInfo {
    margin: 3rem auto 5rem;
    font-size: 1.5rem;
    font-weight: 700;
    width: 90%;
    padding: 1rem;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .userdetails {
      margin-top: 1rem;
      border-top: 1px solid #bbb;
      padding-top: 0.5rem;
      display: flex;
      justify-content: space-between;
      font-size: 1.2rem;
      line-height: 2rem;
      font-weight: 400;
      .name {
        text-transform: capitalize;
      }
    }
  }
  .orderHistory {
    margin: 3rem auto;
    font-size: 1.5rem;
    font-weight: 700;
    width: 90%;
    padding: 1rem;
    .title {
      display: flex;
      font-size: 1.2rem;
      justify-content: space-between;
      margin-top: 3rem;
    }
  }
}

@media screen and (max-width: 1200px) {
  .container {
    width: 85%;
  }
}

@media screen and (max-width: 800px) {
  .container {
    width: 95%;
  }
}

@media screen and (max-width: 600px) {
  .container {
    width: 100%;
    .personalInfo {
      .userdetails {
        display: flex;
        flex-direction: column;
        p {
          margin: 0.5rem 0;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    width: 100%;
    .personalInfo {
      font-size: 1rem;
      width: 95%;
      padding: 0.5rem;
      .userdetails {
        font-size: 0.9rem;
        line-height: 1.5rem;
      }
    }
    .orderHistory {
      font-size: 1rem;
      width: 95%;
      padding: 0.5rem;
      .title {
        display: flex;
        font-size: 0.95rem;
      }
    }
  }
}
