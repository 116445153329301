.container {
  width: 10rem;
  img {
    width: 100%;
  }
}

@media screen and (max-width: 1180px) {
  .container {
    width: 9rem;
    img {
      width: 100%;
    }
  }
}
