.container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  display: flex;
  align-items: center;
  .wrapper {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
}
