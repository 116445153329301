.container {
  margin-top: 1rem;
  border-top: 1px solid #bbb;
  padding-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  font-size: 1.2rem;
  line-height: 2rem;
  font-weight: 400;
  a {
    color: rgb(21, 21, 107);
    text-decoration: underline;
  }
}

@media screen and (max-width: 575px) {
  .container {
    display: flex;
    flex-direction: column;
    font-size: 1.1rem;
    line-height: 2rem;
    font-weight: 400;
  }
}
