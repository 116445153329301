.container {
  display: flex;
  border-top: 1px solid #ccc;
  .product {
    display: flex;
    width: 50%;
    align-items: center;
    justify-content: flex-start;
    .name {
      padding: 0.5rem;
    }
    .image {
      width: 20%;
      img {
        width: 100%;
      }
    }
  }
  .price {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .quantity {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 480px) {
  .container {
    .product {
      width: 70%;
      .name {
        padding: 0.5rem;
      }
      .image {
        width: 15%;
      }
    }
    .price {
      width: 15%;
    }
    .quantity {
      width: 15%;
    }
  }
}

@media screen and (max-width: 420px) {
  .container {
    font-size: 0.8rem;
    .product {
      width: 70%;
      .name {
        padding: 0.5rem;
      }
      .image {
        width: 15%;
      }
    }
    .price {
      width: 15%;
    }
    .quantity {
      width: 15%;
    }
  }
}
