.container {
  width: 60%;
  margin: 4rem auto;
  .orderNumber {
    margin: 1rem auto;
    border-bottom: 1px solid #bbb;
    font-size: 1.3rem;
    font-weight: 700;
    text-align: center;
    padding: 2rem;
  }
  .orderInfo {
    margin: 1rem auto 3rem;
    font-size: 1.1rem;
    display: flex;
    padding: 1rem;
    justify-content: space-between;
    .orderDetails {
      p {
        margin-bottom: 1rem;
        span {
          font-weight: 700;
        }
      }
    }
    .orderAddress {
      .title {
        font-weight: 600;
        margin-bottom: 1rem;
      }
      p {
        margin-bottom: 0.3rem;
      }
    }
  }
  .productList {
    .title {
      font-size: 1.2rem;
      font-weight: 700;
      display: flex;
      .product {
        padding: 1rem;
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .price {
        width: 20%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .quantity {
        width: 20%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .item {
      display: flex;
      border-top: 1px solid #ccc;
      .product {
        display: flex;
        width: 50%;
        align-items: center;
        justify-content: center;
        .name {
          padding: 0.5rem;
        }
        .image {
          width: 20%;
          img {
            width: 100%;
          }
        }
      }
      .price {
        width: 20%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .quantity {
        width: 20%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .container {
    width: 80%;
  }
}

@media screen and (max-width: 600px) {
  .container {
    width: 90%;
  }
}

@media screen and (max-width: 480px) {
  .container {
    width: 95%;
    margin: 2rem auto;
    .orderNumber {
      font-size: 1.1rem;
    }
    .orderInfo {
      font-size: 1rem;
    }
    .productList {
      .title {
        font-size: 1rem;
        .product {
          padding: 0.75rem;
          width: 70%;
        }
        .price {
          width: 15%;
        }
        .quantity {
          width: 15%;
        }
      }
      .item {
        .product {
          width: 70%;
          .name {
            padding: 0.5rem;
          }
          .image {
            width: 15%;
          }
        }
        .price {
          width: 15%;
        }
        .quantity {
          width: 15%;
        }
      }
    }
  }
}

@media screen and (max-width: 420px) {
  .container {
    width: 95%;
    .orderInfo {
      flex-direction: column;
      .orderAddress {
        margin-top: 1rem;
      }
    }
    .productList {
      .title {
        font-size: 0.8rem;
        .product {
          padding: 0.5rem;
          width: 70%;
        }
        .price {
          width: 15%;
        }
        .quantity {
          width: 15%;
        }
      }
      .item {
        font-size: 0.8rem;
        .product {
          width: 70%;
          .name {
            padding: 0.5rem;
          }
          .image {
            width: 15%;
          }
        }
        .price {
          width: 15%;
        }
        .quantity {
          width: 15%;
        }
      }
    }
  }
}
